<template>
    <div >
      <HeaderComp />
      <b-jumbotron class="jumboDark" >
        <b-container >
          <b-row class="justify-content-center" style="margin-bottom:30px;">
          <b-col cols="12" style="margin-top:30px;">
            <div class="events_img main_header"></div>
          </b-col>
        </b-row>
        </b-container>
        <b-container>
          <b-row class="justify-content-center" >
          <b-col cols="12">
            <h1 class="ttl" > 
              <font color="#8eb6c7" weight="bold"> UPCOMING </font>
              <font color="#d8e030"> EVENTS </font>
            </h1>
          </b-col>  
        </b-row>
          <b-row class="justify-content-center" style="margin-bottom:60px;">
            <b-col cols="8" >
              <p style="text-align: center; font-size: larger;"> 
                Specialty classes, team activities, and community outreach hosted by Uplift. </p>
            </b-col>  
          </b-row>
          
        </b-container>
      </b-jumbotron>

      <b-jumbotron class="jumboDark">
        <b-container>
          
          <b-row class="align-content-center" >
            <b-col cols="11" sm="6" > 
              <p class="eventFeatDate" > February 15th </p>
              <p class="eventFeatTitle" > 3rd Annual Glitter and Glue Sticks </p>
              <p class="eventFeatTime" > TBD </p>
            </b-col>
            <b-col cols="11" sm="6">
              <p class="eventFeatDesc" > 
                Join us for our 3rd Annual Valentine's Day card making bash. More details to come.
                <!-- <br>
                Expect to learn: <br>
                - Hard and fast rules while returning to activity <br>
                - What's "too much" vs therapeutic pain with movement <br>
                - Common modifications with specific injuries <br>
                - Appropriate rehab strategies to heal faster <br>
                <br> <br>  -->
                <!-- <b> ALL of our events are now donation based </b> <br> 
                You may donate by either: <br>

                Option 1: Bring in a non-perishable food item for Harvest of Hope Pantry or give a monetary donation on their website<br>

                Option 2: Ask us about our monthly rotating charity and we will give you our Venmo information to donate!  <br> -->
              </p>
              <!-- <b-btn href="https://www.wellnessliving.com/schedule/uplift_boulder?id_class_tab=1&k_class=706050" 
                target="_blank" 
                style="background-color:#8eb6c7 !important; color: black !important; font-size: 22px !important;"> 
                Register
              </b-btn> -->
            </b-col>
          </b-row>
          <div style="margin-bottom:120px;"></div>

          <!-- <b-row class="align-content-center" >
            <b-col cols="11" sm="6" > 
              <p class="eventFeatDate" > October 9th </p>
              <p class="eventFeatTitle" > Strength Training for Endurance Athletes Seminar </p>
              <p class="eventFeatTime" > 6:30 to 7:30pm </p>
            </b-col>
            <b-col cols="11" sm="6">
              <p class="eventFeatDesc" > 
                Do you run/hike/bike a distance of 5K or more? <br>
                Are you wondering how to balance strength training with your endurance activities? <br>
                We have the seminar for you!<br>
                This seminar will highlight how to mitigate overuse injury risk by addressing movement asymmetry or compensation patterns. 
                We believe any endurance athlete would benefit from this seminar, 
                but it would be especially helpful for runners/hikers/bikers who are new to strength training. 
                We will discuss how strength training can improve your performance, recovery and HOW to implement this into your routine.
                <br> <br> 
                <b> ALL of our events are now donation based </b> <br> 
                You may donate by either: <br>
                Option 1: Bring in a non-perishable food item for Harvest of Hope Pantry or give a monetary donation on their website<br>
                Option 2: Ask us about our monthly rotating charity and we will give you our Venmo information to donate!  <br>
              </p>
              <b-btn href="https://www.wellnessliving.com/schedule/uplift_boulder?id_class_tab=1&k_class=602931&k_class_tab=38437" 
                target="_blank" 
                style="background-color:#8eb6c7 !important; color: black !important; font-size: 22px !important;"> 
                Register
              </b-btn>
            </b-col>
          </b-row>
          <div style="margin-bottom:120px;"></div>

          <b-row class="align-content-center" >
            <b-col cols="11" sm="6" > 
              <p class="eventFeatDate" > November </p>
              <p class="eventFeatTitle" > LIFT-A-TON EAT-A-TON </p>
              <p class="eventFeatTime" > November 9th </p>
            </b-col>
            <b-col cols="11" sm="6">
              <p class="eventFeatDesc" > 
                Join us for our annual in house friendly lifting competition for charity.  <br>
                The goal is to find a challenging Bench Press and Deadlift weight 
                for 5 reps.  Uplift will donate $10 to Harvest of Hope for every 
                ton lifted.  <br>
                <i>Members Only</i>
              </p>
            </b-col>
          </b-row> -->
          <div style="margin-bottom:120px;"></div>

          <!-- 
            
            NEW CLASSES 
          
          -->
          <!-- <b-row>  
            <b-col cols="12">
              <h1 class="ttl" > 
                <font color="white" weight="bold"> NEW </font>
                <font color="#d8e030"> CLASSES </font>
              </h1>
            </b-col>  
          </b-row>
          <b-row class="align-content-center" >
            <b-col cols="11" sm="6"> 
              <p class="eventFeatTitle" > Booty Camp is Back! </p>
              <p class="eventFeatTime" > Monday September 30th through December 20th </p>
            </b-col>
            <b-col cols="11" sm="6">
              <p class="eventFeatDesc" > 
                Were you a part of Booty Camp the first time around? <br>
                Did you miss out last time and want in? <br>
                Don’t fret- Jena has you covered! <br> <br>
                What is Booty Camp? <br>
                Booty is a women’s only booty workout group. 
                The goal is to gain size and/or confidence in your booty through a total body program with a *strong* emphasis on glutes. 
                This is a specialty class capped at 6 people. Think of a hybrid between personal training and group class. <br>
                Sessions are 75 minutes long. <br>
                Class Options: <br>
                - Mondays + Thursdays 930-1045AM (FULL) <br>
                - Wednesdays + Fridays at 830-945AM (2 spots remaining) <br>
                Email jena@upliftboulder.com for any questions and to secure your spot!
              </p>            
            </b-col>
          </b-row>
          <div style="margin-bottom:120px;"></div> -->
          <!-- 
            
            NEW Challenge 
          
          -->
          <b-row>  
            <b-col cols="12">
              <h1 class="ttl" > 
                <font color="white" weight="bold"> Programming  </font>
                <font color="#d8e030"> Focus </font>
              </h1>
            </b-col>  
          </b-row>
          <b-row class="align-content-center" >
            <b-col cols="11" sm="6"> 
              <p class="eventFeatTitle" > Single Leg Squat </p>
            </b-col>
            <b-col cols="11" sm="6">
              <p class="eventFeatDesc" > 
                3 Rep Max<br>
                Improve your balance and stability<br>
                Develop coordination<br>
                Strengthen lower body muscles<br>
              </p>            
            </b-col>
          </b-row>
          <b-row class="align-content-center" >
            <b-col cols="11" sm="6"> 
              <p class="eventFeatTitle" > Farmers Carry </p>
            </b-col>
            <b-col cols="11" sm="6">
              <p class="eventFeatDesc" > 
                100 foot max weight <br>
                Improve grip strength <br>
                Strengthen core<br>
                Improve posture<br>
              </p>            
            </b-col>
          </b-row>
          <div style="margin-bottom:120px;"></div>
        <!-- END -->
        </b-container>
      </b-jumbotron>
      
      <!-- <InstagramPost /> -->
      <TestimonialsBanner2 />
      <LaunchBanner />
      <button @click="scrollToTop" class="scroll-to-top"><b-icon-arrow-up/></button>
      <FooterComp />
    </div>
</template> 

<script>
import HeaderComp from '@/components/HeaderComp.vue'
import FooterComp from '@/components/FooterComp.vue'
import LaunchBanner from "@/components/LaunchBanner.vue"
import TestimonialsBanner2 from '@/components/TestimonialsBanner2.vue'

export default {
  name: 'EventsView',
  components: {
    HeaderComp,
    FooterComp,
    LaunchBanner,
    TestimonialsBanner2,
  },
  data() {
    return {
    };
  },
  methods: {
    
  },
  

}
</script>
<style scoped lang="scss">

.events_img {
  background-image: url('../assets/img_events.png');
}
.eventFeat
{
  background-color: black;
  border-color: cyan;
  border-style: double;
  border-width: 20px;
  border-radius: 5px;
}
.eventFeatImg
{
  border: 5px solid rgb(255, 217, 0);
  border-radius: 4px;
  padding: 5px;
  width: 90%;
  height: auto;
  max-width: 600px;
  min-width: 200px;
  margin: auto;
  justify-self: center;
}
.eventFeatCont
{
  margin-left: 0;
  margin-bottom: 30px;
  justify-self: center;
  text-align: left;

}
.eventFeatDate
{
  font-variant-caps: all-small-caps; 
  font-size:2rem;
  margin-bottom:0; 
  font-weight:bold; 
  color:#e9e9e9;
;
}
.eventFeatTime
{
  font-size:1.5rem;
  margin-bottom:0; 
  color:#d8e030;
}
.eventFeatTitle
{
  margin-bottom:0;
  font-family: 'comfortaa';
  font-weight:bold; 
  color: rgb(255, 255, 255);
  font-size: 2rem;
}

.eventFeatDesc
{
  margin-bottom:15px;
  color:#e9e9e9;
  font-size: 1rem;
  text-align: left;
}

.eventFeatPrice
{
  font-size:1rem;
  margin-bottom:30px; 
  font-weight:500; 
  color:cyan;
}


.eventBreak
{
  width: 100px;
  transform: skewX(-20deg);
  border-bottom: 30px solid cyan;
  margin: auto 5px;
  
}
.eventBreakCircle
{
  height: 20px;
  width: 20px;
  background-color: cyan;
  border-radius: 50%;
  margin: auto 5px;
  display: inline-block;
}
.eventCont
{
  width: 300px;
  margin: auto;
  justify-self: center;
  text-align: left;
}
.eventDate
{
  font-variant-caps: all-small-caps; 
  font-size:1.5rem;
  margin-bottom:0; 
  font-weight:bold; 
  color:#7c7c7c;
}
.eventTime
{
  margin-bottom:0; 
  font-size:1.5rem;
  font-weight:bold; 
  color:darkcyan;
}
.eventTitle
{
  margin-bottom:0;
  font-family: 'comfortaa';
  font-weight:bold; 

  color: black;
  font-size:2rem;
}

.eventDesc
{
  margin-bottom:0;
  color:#7c7c7c;
  font-size:1.5rem;
}

.eventPrice
{
  margin-bottom:0; 
  font-weight:500; 
  color:darkcyan;
  font-size:1.5rem;
}
.eventInfoReq
{
  text-align: center;
  margin-top: 20px;
  margin-bottom:0;
  color:black;

}

.eventImg
{
  width: 90%;
  height: auto;
  max-width: 400px;
  min-width: 200px;
  margin: auto;
  justify-self: center;
}


@media (min-width: 768px) {
  .eventFeatCont
  {
    margin-left: 15px;
  }
  .eventCont
  {
    width: 400px;
  }
  .eventBreak
  {
    width: 300px;
  }
  .eventBreakCircle
  {
    height: 30px;
    width: 30px;
  }
}
</style>

  